import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@material-ui/core/Typography";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles({
  time: {
    paddingRight: 40,
    paddingTop: 30,
    paddingBottom: 30,
  },
  Row: {
    height: 100,
    marginTop: "50px",
  },
  image: {
    width: 130,
    height: 90,
    borderRadius: 10,
    overflow: "hidden",
  },
  nameCell: {
    margin: 10,
  },
});
function List({ elements, classesProps = {} }) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const formatTime = (time) => {
    const splitTime = time.split("T");

    const Hours = splitTime[1].split(":");

    const hoursMinute = Hours.splice(0, 2);
    const reformatHours = hoursMinute.join("h");
    return { Hours: reformatHours };
  };

  return (
    <Box
      sx={{
        padding: 4,
        borderRadius: 1,
        display: "flex",
        justifyContent: "center",
      }}
      className={`${classesProps}`}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {elements?.map((element, index) => {
              const Start = formatTime(element.Start);
              const End = formatTime(element.End);
              return (
                <TableRow key={index} className={classes.Row}>
                  <TableCell
                    sx={{
                      display: "flex",
                      flexDirection: isSmDown ? "column" : "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: isSmDown ? "column" : "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={element.ResourceImage}
                        alt=""
                        className={classes.image}
                      />
                      <Box
                        sx={{ textAlign: isSmDown ? "center" : "inherit" }}
                        className={classes.nameCell}
                      >
                        <TableRow>
                          <Typography
                            variant={isSmDown ? "h5" : "h4"}
                            style={{ fontWeight: 600 }}
                          >
                            {element.Society}
                          </Typography>
                        </TableRow>
                        <TableRow>
                          <Typography variant={isSmDown ? "h5" : "h4"}>
                            {element.Name}
                          </Typography>
                        </TableRow>
                      </Box>
                    </Box>
                    <Box>
                      {Start.Hours === "00h00" && End.Hours === "00h00" && (
                        <Typography variant={isSmDown ? "h5" : "h4"}>
                          Toute la journée
                        </Typography>
                      )}
                      {(Start.Hours !== "00h00" || End.Hours !== "00h00") && (
                        <Typography variant={isSmDown ? "h5" : "h4"}>
                          {Start.Hours} - {End.Hours}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default List;
