import { makeStyles } from "@mui/styles";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 210,
  },
  date: {
    color: "black",
  },
}));

function BannerComponent({ logo, nom, title }) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  let date = new Date();
  const currentMonth = () => {
    const strings = [
      "Janvier",
      "Fevrier",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    const getMonth = date.getMonth();
    const current = strings[getMonth];
    return current;
  };
  let currentDate = `${date.getDate()} ${currentMonth()} ${date.getFullYear()}`;

  return (
    <Box
      sx={{
        p: (theme) => theme.spacing(5),
        display: "flex",
        flexDirection: isSmDown ? "column" : "row",
        alignItems: "center",
        width: isSmDown ? "100" : "95%",
        justifyContent: "space-between",
      }}
    >
      <img src={logo} alt="mon logo" className={classes.logo} />
      <Box sx={{ mt: 2 }}>
        <Typography variant={isSmDown ? "h4" : "h2"} className={classes.text}>
          {nom}
        </Typography>
        <Typography variant={isSmDown ? "h5" : "h4"} className={classes.text}>
          {title}
        </Typography>
      </Box>
      <Typography variant={isSmDown ? "h4" : "h3"} className={classes.date}>
        {currentDate}
      </Typography>
    </Box>
  );
}

export default BannerComponent;
