import { Box, CircularProgress, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo, useState } from "react";
import Banner from "./banner";
import List from "./list";
import useSWR from "swr";

const halfWidth = "50vw";
const useStyles = makeStyles({
  listFullWidth: {
    width: `80%`,
  },
  halfWidth: {
    width: halfWidth,
  },
});

function MainPage() {
  const date = useMemo(() => new Date(), []);
  let today = date.getDate();
  const [isSplice, setIsSplice] = useState(null);
  const [list, setList] = useState(null);
  const [listone, setListone] = useState(null);
  const [listtwo, setListtwo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [image, setImage] = useState(null);
  const classes = useStyles();
  const axios = require("axios").default;
  const url = window.location.href;

  const urlTab = url.split("=");
  const callCosoft = urlTab[urlTab.length - 1];

  const { data } = useSWR(
    callCosoft ? `scheduler/${callCosoft}` : null,
    async () => {
      try {
        const r = await axios.get(
          // "http://localhost/cwr.reactapi/api/external-scheduler/getscheduler?uri=" +
          `${callCosoft}`
        );
        setImage(r.data.Image);

        let orders = r.data.OrderResourceRents;
        orders.sort((a, b) => {
          if (a.Start < b.Start) {
            return -1;
          }
          if (a.Start > b.Start) {
            return 1;
          }
          return 0;
        });

        if (orders.length > 5) {
          setIsSplice(true);
          const dataListone = orders.splice(0, Math.ceil(orders.length / 2));
          const dataListtwo = orders.splice(0, orders.length);

          setListone(dataListone);
          setListtwo(dataListtwo);
        } else {
          const dataList = orders.splice(0, 5);
          setList(dataList);
        }
        return r.data;
      } catch (e) {
        return console.error(e);
      }
    }
  );

  React.useEffect(() => {
    setInterval(() => {
      if (date.getDate() === today + 1) {
        window.location.reload();
      }
    }, 1800000);
  }, [date, today]);

  if (!data)
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      {data && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Banner nom={data.Name} title={data.Title} logo={data.Logo} />
          {isSplice ? (
            <Stack direction="row">
              <List elements={listone} classesProps={classes.halfWidth} />
              <List elements={listtwo} classesProps={classes.halfWidth} />
            </Stack>
          ) : (
            <List elements={list} classesProps={classes.listFullWidth} />
          )}
        </Box>
      )}
    </Box>
  );
}

export default MainPage;
